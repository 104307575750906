








import Vue from 'vue'
import { Hub } from 'aws-amplify'

export default Vue.extend({
  name: 'Login',
  mounted() {
    Hub.listen('auth', (data) => {
      if (data.payload.event === 'signIn') {
        this.$router.push('/')
      }
    })
  },
})
